import React, { useState } from 'react';
import { m } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Grid, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MotionViewport, varFade } from '../../components/animate';
import useLocales from '@/locales/useLocales';
import { isMobile } from 'react-device-detect';
import ReactCompareImage from 'react-compare-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Styled Components
const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 4),
  },
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
}));

const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
}));

const ImageBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  aspectRatio: '16/9',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const GeneratedImageBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[8],
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  zIndex: 1,
}));

// Main Component
export default function AIImageComparison() {
  const theme = useTheme();
  const { translate } = useLocales();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const originalImage = 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/392bfd42-b33a-4d1d-fc9f-aeceabf23100/widejeedoo';

  const generatedImages = [
    'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/4eec61f8-8507-4a62-0ec9-55a614d5f900/widejeedoo',
    'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/7dacb9ab-55d3-47e4-fa3c-41899f8ce600/widejeedoo',
    'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/3db2a428-81f6-4dad-18f7-fb80724f9a00/widejeedoo',
    'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/98c2ff86-744d-44dc-9c68-30067eb3d700/widejeedoo',
    'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/8d873402-dcf7-46c5-13ee-1eee4b9d4000/widejeedoo',
    'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/b234147f-cf94-4c2e-6b9f-f773e26cc200/widejeedoo',
  ];

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        {/* Header */}
        <Header>
          <Typography variant={isMobile ? 'h4' : 'h3'} gutterBottom>
            {translate('AIImageComparison.title')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {translate('AIImageComparison.description')}
          </Typography>
        </Header>

        {/* Original Image */}
        <Box
          sx={{
            marginBottom: theme.spacing(8),
          }}
          component={m.div}
          variants={varFade().inUp}
        >
          <ImageBox sx={{ position: 'relative' }}>
            <LazyLoadImage src={originalImage} alt="Original" effect="blur" width="100%" height="100%" style={{ objectFit: 'cover' }} />
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}
            >
              {translate('AIImageComparison.original')}
            </Box>
          </ImageBox>
        </Box>

        {/* Generated Images */}
        <Grid container spacing={4}>
          {generatedImages.map((src, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} position="relative">
              <m.div variants={varFade().inUp}>
                <GeneratedImageBox onClick={() => handleImageClick(src)}>
                  <LazyLoadImage
                    src={src}
                    alt={`Generated ${index + 1}`}
                    effect="blur"
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'cover' }}
                  />
                </GeneratedImageBox>
              </m.div>
              <Box
                sx={{
                  position: 'absolute',
                  top: theme.spacing(5),
                  left: theme.spacing(5),
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                }}
              >
                {translate('AIImageComparison.generated')}
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Comparison Dialog */}
        <Dialog
          open={Boolean(selectedImage)}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              overflow: 'hidden',
            },
          }}
        >
          <Box sx={{ position: 'relative' }}>
            <CloseButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </CloseButton>
            <ReactCompareImage
              leftImage={originalImage}
              rightImage={selectedImage || ''}
              sliderLineColor={theme.palette.primary.main}
              leftImageLabel={translate('AIImageComparison.original')}
              rightImageLabel={translate('AIImageComparison.generated')}
            />
          </Box>
        </Dialog>
      </Container>
    </StyledRoot>
  );
}
