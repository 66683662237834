import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Box } from '@mui/material';
import MainLayout from '../layouts/main';
import ScrollProgress from '../components/scroll-progress';
import { HomeHero } from '@/sections/home';
import { Capacitor } from '@capacitor/core';
import { useLocales } from '../locales';
import CategoryTabs from '@/sections/home/CategoryTabs';
import CategoryTabsOberserver from '@/sections/home/CategoryTabsOberserver';
import AIPortraitShowcase from '@/sections/home/AIPortraitShowcase';
import LandingPageNormal from '@/sections/home/LandingPageNormal';
import HeroSection from '@/sections/home/HeroSection';
import AIImageComparison from '@/sections/home/AIImageComparison';

// Dynamically import components
const HomeAdvertisement = dynamic(() => import('../sections/home/HomeAdvertisement'));
const HomeColorPresets = dynamic(() => import('../sections/home/HomeColorPresets'));
const HomeForDesigner = dynamic(() => import('../sections/home/HomeForDesigner'));
const VideoHero = dynamic(() => import('@/sections/home/VideoHero'));

HomePage.getLayout = (page: React.ReactNode) => <MainLayout> {page} </MainLayout>;

export default function HomePage() {
  const [isVisible, setIsVisible] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const { translate } = useLocales();

  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    if (!isNative) return;

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000); // 2 second delay for background

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isNative) return;
    if (!isVisible) {
      const welcomeTimer = setTimeout(() => {
        setShowWelcome(true);
      }, 500); // Show Welcome 0.5 seconds after initial render

      const hideWelcomeTimer = setTimeout(() => {
        setShowWelcome(false);
      }, 1500); // Hide Welcome 0.5 seconds before background starts to fade

      return () => {
        clearTimeout(welcomeTimer);
        clearTimeout(hideWelcomeTimer);
      };
    }
  }, [isVisible]);

  return (
    <>
      <Head>
        <title>MyPortrait Studio / generate your Portraits</title>
      </Head>

      {isNative && !isVisible && (
        <div
          style={{
            opacity: isVisible ? 0 : 1,
            transition: 'opacity 1s ease-in-out',
            backgroundColor: 'black',
            zIndex: 1200,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: '2rem',
              opacity: showWelcome ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {translate('home.welcome')}
          </div>
        </div>
      )}
      <ScrollProgress />

      <HomeHero />

      <Box
        sx={{
          boxShadow: '0px -10px 20px rgba(0, 0, 0, 0.3)',
          overflow: 'hidden',
          position: 'relative',
          bgcolor: 'background.default',
          paddingBottom: { xs: 10, md: 20 },
        }}
      >
        <AIImageComparison />
        <AIPortraitShowcase />
        <VideoHero />
        <LandingPageNormal />

        {/* <BetaFree /> */}
        {/* <HomeColorPresets /> */}
        <CategoryTabsOberserver />
        <HeroSection />

        {/* <HomeForDesigner /> */}
        {/* <HomeAdvertisement /> */}
      </Box>
    </>
  );
}
