import React, { useState, useEffect, useRef, useCallback } from 'react';
import CategoryTabs from './CategoryTabs';
import { useStateDataRequest } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';
import { CategoryViewModel } from '@models/portraitSetTypes';
import { AIPortraitShowcaseGender } from './AIPortraitShowcase';
import { useAtom } from 'jotai';

const CategoryTabsObserver: React.FC = () => {
  const [categories, setCategories] = useState<CategoryViewModel[] | null>(null);
  const [loadedCategories, setLoadedCategories] = useState<Set<string>>(new Set());
  const containerRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
  const [gender, setGender] = useAtom(AIPortraitShowcaseGender);

  // Fetch categories from the backend
  useStateDataRequest(SocketEmits.GetCategories, {}, setCategories);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const category = entry.target.getAttribute('data-category');
          if (category && !loadedCategories.has(category)) {
            setLoadedCategories((prev) => {
              const newSet = new Set(prev);
              newSet.add(category);
              return newSet;
            });
            observer.unobserve(entry.target);
          }
        }
      });
    },
    [loadedCategories]
  );

  useEffect(() => {
    if (!categories || categories.length === 0) return; // Avoid setting up observer if no categories

    const observer = new IntersectionObserver(handleIntersection, { rootMargin: '200px' });

    categories.forEach((category) => {
      const ref = containerRefs.current.get(category.category);
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, [categories, handleIntersection]);

  useEffect(() => {
    // Reset loaded categories when gender changes
    setLoadedCategories(new Set());
  }, [gender]);

  return (
    <div style={{ marginTop: 20 }}>
      {categories &&
        categories
          .slice()
          .reverse()
          .map((category) => (
            <div
              key={category.category}
              data-category={category.category}
              ref={(el) => {
                if (el && !containerRefs.current.has(category.category)) {
                  containerRefs.current.set(category.category, el);
                }
              }}
            >
              {loadedCategories.has(category.category) && <CategoryTabs activeCategory={category.category} />}
            </div>
          ))}
    </div>
  );
};

export default CategoryTabsObserver;
